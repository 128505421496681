<template>
  <div class="container">
    <PublicHeader totalName="SWIFT Code" />
    <div class="top">
      <van-field
        v-model="form.CardUserName"
        :disabled="disabled"
        :label="$t('SWIFTCode').CardUserName"
        :placeholder="$t('SWIFTCode').placeholder1"
      />
      <van-field
        v-model="form.Area"
        :disabled="disabled"
        :label="$t('SWIFTCode').Area"
        :placeholder="$t('SWIFTCode').placeholder2"
      />
      <van-field
        v-model="form.DetailArea"
        :disabled="disabled"
        :label="$t('SWIFTCode').DetailArea"
        :placeholder="$t('SWIFTCode').placeholder3"
      />
      <van-field
        v-model="form.OpenBank"
        :label="$t('SWIFTCode').OpenBank"
        disabled
        :placeholder="$t('SWIFTCode').placeholder4"
        @click-right-icon="show = true"
        :right-icon="!disabled ? 'wap-nav' : null"
      />
      <i></i>
    </div>
    <van-field
      v-model="form.SWIFTCode"
      label="SWIFT Code"
      :disabled="disabled"
      :placeholder="$t('SWIFTCode').usdt"
    />
    <van-field
      v-model="form.CardNo"
      :disabled="disabled"
      :label="$t('SWIFTCode').CardNo"
      :placeholder="$t('SWIFTCode').btc"
    />
    <van-field
      v-if="Liquidation"
      :disabled="disabled"
      v-model="form.LiquidationNumber"
      :label="Liquidation"
      :placeholder="$t('SWIFTCode').eth + Liquidation"
    />
    <i></i>
    <div v-if="IsBindBank && !basicConfig.IsEditBank" class="info">
      {{ $t('SWIFTCode').info }}
    </div>
    <div v-if="basicConfig.IsShowCurrency">
      <van-divider dashed>{{ $t('bank').DigitalCurrency }}</van-divider>

      <van-field
        v-model="form.USDT"
        label="USDT"
        :disabled="disabled"
        :placeholder="$t('bank').usdt"
      />
      <van-field
        v-model="form.BTC"
        label="BTC"
        :placeholder="$t('bank').btc"
        :disabled="disabled"
      />
      <van-field
        v-model="form.ETH"
        label="ETH"
        :placeholder="$t('bank').eth"
        :disabled="disabled"
      />
    </div>
    <van-button size="large" v-if="!disabled" @click="handleSubmit">{{
      IsBindBank ? $t('SWIFTCode').update : $t('SWIFTCode').submit
    }}</van-button>
    <van-popup v-model="show" position="bottom">
      <van-picker
        :title="$t('public').select"
        :confirm-button-text="$t('public').confirm"
        :cancel-button-text="$t('public').cancel"
        show-toolbar
        :default-index="defaultIndex"
        value-key="Country"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      value: '',
      show: false,
      IsBindBank: false,
      columns: [],
      defaultIndex: 0,
      Liquidation: '',
      form: {
        CardUserName: '',
        SwiftCodeId: null,
        USDT: null,
        BTC: null,
        ETH: null,
        Area: null,
        DetailArea: '',
        OpenBank: '', // 开户国家
        SWIFTCode: '',
        CardNo: '',
        LiquidationNumber: '',
      },
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
    disabled() {
      return this.IsBindBank && !this.basicConfig.IsEditBank
    },
  },
  methods: {
    onConfirm(value) {
      this.form.OpenBank = value.Country
      this.form.SwiftCodeId = value.Id
      this.Liquidation = value.LiquidationCode
      this.show = false
    },
    async SWIFTCodeIdName() {
      this.columns = await userApi.SWIFTCodeIdName()
      this.getUserInfo()
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      for (let i in this.form) {
        this.form[i] = res[i]
      }
      this.IsBindBank = res.IsBindBank

      if (this.IsBindBank && !this.basicConfig.IsEditBank) {
        let length = this.form.SWIFTCode.length
        this.form.SWIFTCode =
          '**** **** **** ' + this.form.SWIFTCode.substring(length - 4)
      }

      this.columns.forEach((item, index) => {
        if (item.Id === this.form.SwiftCodeId) {
          this.defaultIndex = index
          // this.form.OpenBank = item.Country
          this.Liquidation = item.LiquidationCode
          return
        }
      })
    },
    async handleSubmit() {
      let form = { ...this.form }

      if (form.CardUserName === '') {
        this.$toast({
          message: this.$t('SWIFTCode').placeholder1,
          position: 'bottom',
        })
        return
      }
      if (form.Area === '') {
        this.$toast({
          message: this.$t('SWIFTCode').placeholder2,
          position: 'bottom',
        })
        return
      }
      if (form.DetailArea === '') {
        this.$toast({
          message: this.$t('SWIFTCode').placeholder3,
          position: 'bottom',
        })
        return
      }
      if (form.OpenBank === '') {
        this.$toast({
          message: this.$t('SWIFTCode').placeholder4,
          position: 'bottom',
        })
        return
      }
      if (form.SWIFTCode === '') {
        this.$toast({
          message: this.$t('SWIFTCode').usdt,
          position: 'bottom',
        })
        return
      }
      if (form.CardNo === '') {
        this.$toast({
          message: this.$t('SWIFTCode').btc,
          position: 'bottom',
        })
        return
      }
      if (form.LiquidationNumber === '') {
        this.$toast({
          message: this.$t('SWIFTCode').eth + this.Liquidation,
          position: 'bottom',
        })
        return
      }
      if (this.IsBindBank) {
        await userApi.updateSwift(form)
      } else {
        await userApi.addSwift(form)
      }

      this.$toast.success(this.$t('public').SubmittedSuccessfully)
      this.$router.go(-1)
    },
  },
  mounted() {
    this.SWIFTCodeIdName()
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  color #fae39c
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  .van-field
    margin 0
    background none
    >>> .van-field__label
      color #ebe5cd
    >>> .van-field__control
      color #fff
    >>> input::-webkit-input-placeholder
      color #969799
  .van-button
    width calc(100% - 30px)
    border none
    margin 21px 15px 23px
    color #000
    font-size 14px
    height 44px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important
  .van-picker
    color #000
  .info
    margin 20px
    color #e60d0d
</style>
